// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBXrItjJMBHkOuuxFwLygoACsO7mBO6ksk",
  authDomain: "fahamni-86e59.firebaseapp.com",
  projectId: "fahamni-86e59",
  storageBucket: "fahamni-86e59.appspot.com",
  messagingSenderId: "709861583886",
  appId: "1:709861583886:web:fefa4f14e94800f5b1205f",
  measurementId: "G-7HRQE7W3CZ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
