import { Auth, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./firebase-init";

class LoginService {
  private auth: Auth = getAuth();

  async login(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return userCredential.user.getIdToken();
    } catch (e: unknown) {
      console.log(e);
    }
  }
}

const loginService = new LoginService();

export default loginService;
