import React from "react";
import { Input, Box, Button, Heading, Image, Progress } from "@chakra-ui/react";
import logo from "../assets/images/logo.png";
import loginService from "../services/login-service";

export function LoginPage() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [redirecting, setRedirecting] = React.useState(false);

  const handleEmailUpdate = React.useCallback((e: any) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordUpdate = React.useCallback((e: any) => {
    setPassword(e.target.value);
  }, []);

  const handleSubmit = React.useCallback(
    async (e: any) => {
      e.preventDefault();
      setRedirecting(true);
      const token = await loginService.login(email, password);
      // eslint-disable-next-line no-restricted-globals
      location.href = `https://call.fahamni.live/test?jwt=${token}`;
    },
    [email, password]
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" h="100vh">
      {redirecting && <Progress size="xs" isIndeterminate />}
      {!redirecting && (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="space-between"
          w="50%"
          h="400px"
        >
          <Image
            boxSize="200px"
            objectFit="contain"
            src={logo}
            alt="Fahmni logo"
          />
          <Heading>Bienvenue Fahamni</Heading>
          <Input
            type="email"
            placeholder="email"
            onChange={handleEmailUpdate}
          />
          <Input
            type="password"
            placeholder="password"
            onChange={handlePasswordUpdate}
          />
          <Button colorScheme="blue" onClick={handleSubmit}>
            Login
          </Button>
        </Box>
      )}
    </Box>
  );
}
